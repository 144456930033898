// Sing variables
// -------------------------
// -------------------------

$vendor-folder: "../../node_modules" !default;

// Glyphicons

$glyphicons-halflings-include-bonus: false !default;
$glyphicons-halflings-class-prefix: glyphicon !default;
$glyphicons-halflings-font-base-size: 14px !default;

// Bootstrap options

$enable-shadows: true !default;

// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d6dee5 !default;
$gray-400: #c1ccd3 !default;
$gray-500: #a3aeb7 !default;
$gray-600: #798892 !default;
$gray-700: #495057 !default;
$gray-750: #F6F6E9 !default;
$gray-800: #3c484f !default;
$gray-900: #29323a !default;
$black: #000 !default;
$gray-light: $gray-100 !default;


$default: #474d84;
$blue: #1870dc !default;
$skyblue: #4ebfbb !default;
$indigo: #7f3ddb !default;
$purple: #9964e3 !default;
$pink: #e54e9a !default;
$red: #f45722 !default;
$orange: #ff7d47 !default;
$yellow: #fda700 !default;
$green: #68ca66 !default;
$teal: #58d777 !default;
$cyan: #17a2b8 !default;

$theme-colors: (
  primary: $blue,
  secondary: #f4f4f5e6,
  success: $teal,
  primary-light: #dee4ee,
  success-light: #ecfaec,
  info-light: #f2fafa,
  warning-light: #fdf7e6,
  danger-light: #fff2ef,
  info: $skyblue,
  warning: $yellow,
  danger: $red,
  dark: $gray-800,
  inverse: #002B49,
  gray: #d7dfe6,
);

//== Scaffolding
//
$body-bg: #f9fbfd !default;
$body-bg-light: lighten($body-bg, 3%);

$addition-bg: $gray-200 !default;

$logo-color: $gray-750;

// Typography
// -------------------------

$body-color: $gray-700 !default;
$body-main-color: #f4f4f5e6 !default;
$body-main-color-small: #f4f4f5b3 !default;
//$body-color: #57534A; //a bit brown?

$font-size-initial: 14px; // reset default browser value from 16px to 13px
$font-size-xlg: 1.5rem !default;
$font-size-base: 1rem !default;
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$font-size-larger: 1.1rem;
$font-size-mini: 0.9rem;
$font-size-index: 0.7rem;

$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-normal: 400 !default;
$font-weight-thin: 300 !default;
$font-weight-base: $font-weight-thin !default;

$font-size-smaller: 85% !default;

$line-height-base: 1.55 !default;
$link-color: #218bc3 !default;

// Layout
// -------------------------
$sidebar-padding-horizontal: 11px;
$sidebar-icon-state-width: 50px !default;
$sidebar-slim-scroll-width: 4px;
$sidebar-width: 220px + $sidebar-slim-scroll-width !default;

$sidebar-transition-time: 0.3s !default;

$sidebar-action-bg: #042338 !default;
$sidebar-bg-color: #002B49 !default;
$sidebar-color: #c2c2c2 !default;
$sidebar-white-color: #6c757d !default;
$sidebar-first-color: #004472 !default;
$sidebar-first-color-bg: #00395f !default;
$sidebar-second-color-bg: #e9ebef !default;
$sidebar-second-color: #004472 !default;
$sidebar-second-color-bg-active: #dbdee4 !default;
$sidebar-third-color: #004472 !default;
$sidebar-third-color-bg: #d1e7f5 !default;
$sidebar-third-color-bg-active: #bedcf2 !default;
$sidebar-fourth-color-bg: #ccdde9 !default;
$sidebar-fourth-color-bg-active: #bacedc !default;
$sidebar-fifth-color-bg: #d6dfe6 !default;
$sidebar-fifth-color-bg-active: #bdc9d4 !default;
$sidebar-sixth-color-bg: #13191d !default;
$sidebar-sixth-color-bg-active: #080c0f !default;
$sidebar-seventh-color: #f6f6e9 !default;
$sidebar-seventh-color-bg: #20ae8c !default;
$sidebar-seventh-color-bg-active: #119375 !default;
$sidebar-font-weight: 300;
$sidebar-item-border-color: transparent !default;
$sidebar-item-active-color: $gray-750 !default;
$sidebar-item-hover-color: lighten($sidebar-color, 10%);
$sidebar-item-hover-bg-color: $sidebar-action-bg;
$sidebar-ul-background: $sidebar-action-bg;
$sidebar-border-color: darken($sidebar-ul-background, 2%);

$navbar-height: 60px !default;
$navbar-font-size: 1rem !default;
$navbar-bg: transparent;
$navbar-shadow: none;

$chat-sidebar-width: 270px + $sidebar-slim-scroll-width !default;
$chat-active-bg-color: #4eaae9 !default;
$chat-avatar-bg: #fd5f00 !default;
$chat-img-hw: 35px !default;


$content-padding-vertical: 42px !default;
$content-padding-top: $content-padding-vertical + $navbar-height !default;
$content-padding-horizontal: 40px !default;

$grid-gutter-width: 40px;

$border-radius: 0.3rem !default;
$border-radius-xlg: 0.625rem !default;
$border-radius-lg: 0.35rem !default;
$border-radius-sm: 0.25rem !default;

// Screens
// -------------------------

$screen-lg-height: 670px !default;

// Widgets
// -------------------------

$widget-padding-vertical: 20px !default;
$widget-padding-horizontal: 20px !default;
$widget-bg-color: rgba(0,0,0, 0.24);
$widget-color: rgba(244,244,245,.6);
$widget-color-header: rgba(244,244,245,.9);
$widget-shadow-color: 75,102,171;
$widget-shadow: 0 25px 20px -20px rgba(0,0,0,.1), 0 0 15px rgba(0,0,0,.06);
$widget-card-shadow: 4px 4px 10px 0px rgba(75,102,171,0.2);
$widget-border-color: #040620 !default;
$widget-author-color: #7ca9dd !default;
$widget-dropdown-color: rgba(0,0,0,.8) !default;
$widget-shadow-designated: -1px 0 8px rgba(0, 0, 0, 0.2);
$widget-border-radius: $border-radius-xlg;

$chart-spacer-y: 0.65rem !default;

// Bootstrap variables
// ------------------------
// ------------------------

$card-border-radius: $widget-border-radius;

//== Breadcrumbs
//
$breadcrumb-divider: ">";
$breadcrumb-padding-y: 0;
$breadcrumb-bg: transparent;

// Typography
// -------------------------

$font-family-sans-serif: "Montserrat", sans-serif !default;

$headings-font-weight: 400 !default;

//== Forms
//
$legend-color: lighten($body-color, 15%) !default;
$cursor-disabled: not-allowed !default;

//== Tables
//
$table-accent-bg: $gray-100 !default;
$table-hover-bg: $gray-200 !default;

//== Buttons
//

$btn-default-color: #1b1e3c !default;
$btn-default-bg: $addition-bg !default;
$btn-default-border: $addition-bg !default;

//== Dropdowns
//
$dropdown-link-color: $body-color !default;
$dropdown-border-color: $gray-200;

//== Navs
//

$nav-tabs-active-link-hover-bg: $white !default;
$nav-tabs-active-link-hover-color: $gray-700 !default;
$nav-tabs-active-link-hover-border-color: #ddd !default;

//== Navbar
//

$navbar-margin-bottom: 0 !default;
$navbar-border-radius: 0 !default;

$navbar-dashboard-bg: $white !default;
$navbar-dashboard-color: #777 !default;
$navbar-dashboard-border: darken($navbar-dashboard-bg, 6.5%) !default;

// Navbar links
$navbar-dashboard-link-color: #666 !default;

// Navbar links
$navbar-dashboard-link-hover-color: #d9d9dde6 !default;
$navbar-dashboard-link-hover-bg: transparent !default;
$navbar-dashboard-link-active-color: #555 !default;
$navbar-dashboard-link-active-bg: darken($navbar-dashboard-bg, 6.5%) !default;
$navbar-dashboard-link-disabled-color: #ccc !default;
$navbar-dashboard-link-disabled-bg: transparent !default;

// Navbar brand label
$navbar-dashboard-brand-color: $navbar-dashboard-link-color !default;
$navbar-dashboard-brand-hover-color: darken(
  $navbar-dashboard-brand-color,
  10%
) !default;
$navbar-dashboard-brand-hover-bg: transparent !default;

// Navbar toggle
$navbar-dashboard-toggle-hover-bg: #ddd !default;
$navbar-dashboard-toggle-icon-bar-bg: #888 !default;
$navbar-dashboard-toggle-border-color: #ddd !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-bg: #ecfaec !default;
$state-info-bg: #f2fafa !default;
$state-warning-bg: #fdf7e6 !default;
$state-danger-bg: #fff2ef !default;

$state-success-text: #3c763d !default;
$state-success-border: darken($state-success-bg, 5%) !default;

$state-info-text: #31708f !default;
$state-info-border: darken($state-info-bg, 7%) !default;

$state-warning-text: #8a6d3b !default;
$state-warning-border: darken($state-warning-bg, 5%) !default;

$state-danger-text: #a94442 !default;
$state-danger-border: darken($state-danger-bg, 5%) !default;

//== Progress bars
//

//** Background color of the whole progress component
$progress-bg: $gray-200 !default;

//== Tooltips
//

$tooltip-bg: $widget-bg-color !default;
$tooltip-opacity: 1;
$tooltip-color: $body-color;
$tooltip-border-radius: $widget-border-radius;
$tooltip-padding-x: 0.5rem;
$tooltip-padding-y: 0.75rem;

//== Code
//

$code-bg: #f9f2f4 !default;
$pre-bg: #f5f5f5 !default;

//== Popovers
//

$popover-bg: #ebeff1 !default;
$popover-arrow-outer-color: $popover-bg !default;
$popover-arrow-color: $popover-bg !default;

//== Thumbnails
//
//##

$thumbnail-bg: $white !default;
$thumbnail-box-shadow: $widget-shadow;

//== Breadcrumbs
//

$breadcrumb-padding-horizontal: 0 !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-color: $body-color !default;
$breadcrumb-active-color: $body-color !default;
$breadcrumb-separator: ">" !default;

//== Pagination
//
//##

$pagination-color: $gray-600 !default;
$pagination-bg: #fff !default;
$pagination-border: transparent !default;

$pagination-hover-color: $body-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border: transparent !default;

$pagination-active-color: $body-color !default;
$pagination-active-bg: $gray-200 !default;
$pagination-active-border: transparent !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: transparent !default;

// Alerts

$alert-transparent: 0.25;

//-- Iconography
//

$icon-font-path: "../../node_modules/bootstrap-sass/assets/fonts/bootstrap/" !default;

// Font Awesome variables
// ------------------------
// ------------------------

$fa-font-path: "../../node_modules/font-awesome/fonts" !default;
