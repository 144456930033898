@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700');

// Glyphicons

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'),
  url('../fonts/glyphicons-halflings-regular.woff') format('woff'),
  url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'),
  url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

// Flaticon

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/Flaticon.eot");
  src: url("../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon/Flaticon.woff") format("woff"),
       url("../fonts/flaticon/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}